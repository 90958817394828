<template>
    <div class="about-page">
        <section class="page-kv p-0">
            <div class="kv-img ratio-kv">
                <!-- 桌機圖 -->
                <img :src="aboutImage.aboutBannerPc" :alt="meta.alt.alt_5_1" class="w-100 d-none d-md-inline-block" v-if="aboutImage.aboutBannerPc != ''">
                <!-- 手機圖 -->
                <img :src="aboutImage.aboutBannerMobile" :alt="meta.alt.alt_5_1" class="w-100 d-inline-block d-md-none" v-if="aboutImage.aboutBannerMobile != ''">
            </div>
            <div class="kv-heading">
                <h1>公司簡介</h1>
            </div>
        </section>
        <div class="page-main-content">
            <div class="row about-block pt-3" v-if="width > 767">
                <div class="col-12" v-html="about.summary"></div>
            </div>
            <div class="row about-block pt-5" v-else>
                <div class="col-12" v-html="about.summaryMobile"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'About',
    data() {
        return {
            width: 0,
            aboutImage: {
                aboutBannerPc: '',
                aboutBannerMobile: '',
            },
            about: {},
            meta: {},
        }
    },
    mounted() {
        let house = this;
        this.width = $(window).width();
        this.axios.get('/api/about')
            .then(response => (
                    this.aboutImage = response.data.aboutImage,
                        this.about = response.data.about,
                        this.meta = response.data.meta,
                        document.title = this.meta.title,
                        $("meta[property='og:title']").attr("content", this.meta.title),
                        document.getElementsByTagName('meta')["description"].content = this.meta.description,
                        $("meta[property='og:description']").attr("content", this.meta.description)
                )
            )
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });

        $(window).resize(function () {
            let nowWidth = $(window).width();
            house.width = nowWidth;
        });
    },
}
</script>
